import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { HashLink as Link } from 'react-router-hash-link'

import Button from '@material-ui/core/Button'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

const styles = (theme) => ({
    button: {
        boxShadow:'none'
    }
})



const ToTopButton = (props) => {
    const { classes } = props
    return (
        <Link to={'/#top'} smooth>
            <Button className={classes.button} variant='raised'>
                <KeyboardArrowUp />
            </Button>
        </Link>
    )
}

ToTopButton.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(ToTopButton)

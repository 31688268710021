import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'


const styles = (theme) => ({
    root: {
        padding: '0.5rem',
        [theme.breakpoints.up('md')]:{
            padding: '4rem'
        }
    },
})

const Section = (props) => {
    const { classes } = props
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}

Section.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Section)



import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { Parallax } from 'react-parallax'

import { slugify } from '../utils'
import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionContent from '../components/SectionContent'
import ContactUsButton from '../components/ContactUsButton'
import easyBackground from '../assets/healthcare-back-img-only-high-res.jpg'
import mobileEasyBackground from '../assets/healthcare-back-img-only-low-res.jpg'

const styles = (theme) => ({
    imageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: `url(${easyBackground}) right center no-repeat`,
            backgroundSize: '50%, 400%',
            paddingTop: 20,
            paddingBottom: 50,
        }
    },
    parallaxImage: {
        '& > img': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
            }
        }
    },
    mobileParallaxImage: {
        width: '100%',
        minHeight: 250,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    imageText: {
        textShadow: 'rgba(255, 255, 255, 0.75) 1px 0 3px'
    },
})

const heading = "Healthcare"

const paragraphs = [
    '<strong>Empowering patients to take control of their own health has never been easier than with personalized video</strong>.',
    'Video Modify allows healthcare organizations to provide engaging, actionable video content for patients about their medical results. With millions of possible scene variations, patients will always receive individualized explanations and guidance about their specific test results and risk factors.',
    'Our advanced video system is HIPAA compliant and allows healthcare providers to deliver targeted wellness advice, <strong>driving better health outcomes</strong> and creating a superior patient experience.',
]

const Easy = (props) => {
    const { classes } = props
    return(
        <React.Fragment>
            <Hidden mdUp>
                <Parallax
                    blur={0}
                    bgImage={mobileEasyBackground}
                    bgImageAlt="Macbook and Coffee on a Table"
                    strength={100}
                    className={classes.mobileParallaxImage}
                    />
            </Hidden>
            <Parallax
                blur={0}
                bgImage={easyBackground}
                bgImageAlt="Macbook and Coffee on a Table"
                strength={100}
                className={classes.parallaxImage}
                >
        {/* <div className={classes.imageWrapper}> */}
            <Section>
                <div id={slugify(heading)} />
                <Grid container>

                    <Grid item md={6} xs={12}>
                        <SectionHeading text={heading} className={classes.heading} alignOverride="left"/>
                        {paragraphs.map((paragraph, index) => (
                            <SectionContent key={index} text={paragraph} />
                        ))}
                        <ContactUsButton text={'Get Started'} />
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                </Grid>
            </Section>
            </Parallax>
        {/* </div> */}
        <SectionDivider color={'transparent'}/>
        </React.Fragment>
    )
}

Easy.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Easy)

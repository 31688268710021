import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const textColor = 'rgba(0, 0, 0, 0.7)'
const styles = (theme) => ({
    root: {
        paddingTop: '2rem',
        paddingBottom: '1rem',
        color: textColor,
        fontWeight: '300',
        paddingLeft: '1rem',
        fontSize: '1.0rem',
        letterSpacing: 4,
        lineHeight:'1.7rem',
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            paddingTop: '2rem',
            paddingBottom: '1.5rem',
            fontSize: '1.5rem',
            lineHeight:'2rem',
        }
    },
    alignLeft: {
      textAlign: 'left',
      paddingLeft: '1rem',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '2.9rem',
      }
    },
    alignRight: {
      textAlign: 'right'
    }
})

const SectionHeading = (props) => {
    const { classes } = props

    if(props.alignOverride === 'left'){
      return (
          <Typography variant='title' className={classes.root + ' ' + classes.alignLeft} >{props.text}</Typography>
      )
    }else if(props.alignOverride === 'right'){
      return (
          <Typography variant='title' className={classes.root + ' ' + classes.alignRight} >{props.text}</Typography>
      )
    }else{
      return (
          <Typography variant='title' className={classes.root} >{props.text}</Typography>
      )
    }

}

SectionHeading.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    alighnOverride: PropTypes.string,
}

export default withStyles(styles)(SectionHeading)

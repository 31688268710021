import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { HashLink as Link } from 'react-router-hash-link'
import Hidden from '@material-ui/core/Hidden'

import { slugify } from "../utils"
import logo from "../assets/Logo-only-white.svg"

const styles = (theme) => ({
    root:  {
        display: 'flex',
        flexGrow: 1,
        flex: 1,
        justifyContent: 'center',
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: '-1rem',
        marginRight: '2rem',
    },
    menuIcon: {
        color: 'white'
    },
    menuItemOther: {
      fontWeight:300,
      fontSize:'0.75rem'
    },
    link: {
        textDecoration: 'none',
        color: '#ED4037',
        width: '100%',
        letterSpacing:'4px',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            color: 'white',
            fontSize: '1.5rem',
            marginRight: '1.5rem',
        },
        '&:last-child': {
          marginRight:'0px'
        }
    },
    toolbar: {
        backgroundColor: '#ED4037', //'#FDFBFC',
        height: 'inherit',
        minHeight: 'inherit',
        letterSpacing: '2px',
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#ED4037',
            justifyContent: 'center'
        },
        ...theme.mixins.toolbar,
    },
    mobileLogo: {
        maxHeight: '2rem',
    },
    menuSpacer: {
        flexGrow: 1
    },
})

class ResponsiveMenuBar extends Component {
    state = {
        anchor: null,
    }
    handleMenu = (event) => {
        this.setState({ anchor: event.currentTarget })
    }
    handleClose = () => {
        this.setState({anchor: null})
    }
    render() {
        const {classes, menuItems} = this.props
        const {anchor} = this.state
        const open = Boolean(anchor);

        return (
            <div className={classes.root}>
                <AppBar position="static" elevation={0}>
                    <Toolbar className={classes.toolbar}>
                    <div id='top' />
                        <Hidden mdUp>
                            <img alt="VideoModify Logo" src={logo} className={classes.mobileLogo}/>
                            <div className={classes.menuSpacer} />
                            <IconButton
                                className={classes.MenuBotton}
                                color="inherit"
                                aria-label="Menu"
                                onClick={this.handleMenu}
                            >
                                <MenuIcon className={classes.menuIcon}/>
                            </IconButton>
                            <Menu
                                id="navigation-menu"
                                disableRestoreFocus={true}
                                anchorEl={anchor}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                {menuItems.map((item, index) => {
                                    return (
                                        <MenuItem key={index} onClick={this.handleClose}>
                                          <Link className={classes.link} smooth to={`/#${slugify(item)}`}>
                                              {item}
                                          </Link>
                                        </MenuItem>
                                )})}
                            </Menu>
                        </Hidden>
                        <Hidden smDown>
                            {menuItems.map((item, index) => (
                                <Link className={classes.link} key={index} smooth to={`/#${slugify(item)}`}>
                                    <Typography className={classes.menuItem + ' ' + classes.menuItemOther} color='inherit'>
                                        { item.toUpperCase() }
                                    </Typography>
                                </Link>
                            ))}
                        </Hidden>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

}

ResponsiveMenuBar.propTypes = {
    classes: PropTypes.object.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default withStyles(styles)(ResponsiveMenuBar)

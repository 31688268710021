import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { Parallax } from 'react-parallax'

import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionContent from '../components/SectionContent'
import SectionList from '../components/SectionList'
import whatIsBackground from '../assets/what-is-back-high-res.jpg'
import mobileWhatIsBackground from '../assets/what-is-back-mobile-high-res.jpg'

const styles = (theme) => ({
    imageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: `url(${whatIsBackground}) top center no-repeat`,
            backgroundSize: '150%, 100%',
            paddingTop: 0,
            paddingBottom: 50,
        }
    },
    parallaxImage: {
        '& > img': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
            }
        }
    },
    mobileParallaxImage: {
        width: '100%',
        minHeight: 250,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    imageText: {
        textShadow: 'rgba(255, 255, 255, 0.75) 1px 0 3px'
    },
})
const listHeading = `Video Modify creates large-scale personalized video content. By using over 50 unique variables, we create <strong>hyper-relevant videos</strong> to inspire, educate, motivate, and <strong>empower each viewer</strong>:`
const listItems = [
    '<strong>Script writing and variable scene mapping</strong>',
    '<strong>Professional video shoots</strong>',
    '<strong>Automated editing, motion graphics, and audio</strong>',
    '<strong>Video hosting and delivery</strong>',
    '<strong>Easily embedded video players</strong>',
    '<strong>Closed captioning / subtitles</strong>',
    '<strong>API functionality</strong>',
    '<strong>Viewership analytics</strong>'
]
const paragraphs = [
    `Video Modify’s advanced automation process makes it possible to create personalized video on a massive
    scale. No longer do you need to make one video for an audience of millions when you can now make millions of
    videos, each for an audience of one.`,
]

const WhatIs = (props) => {
    const { classes } = props
    return(
      <div id="what-is-video-modify">
        <React.Fragment>
            <Hidden mdUp>
                <Parallax
                    blur={0}
                    bgImage={mobileWhatIsBackground}
                    bgImageAlt="Man hiking with camera"
                    strength={100}
                    className={classes.mobileParallaxImage}
                    />
            </Hidden>
            <Parallax
                blur={0}
                bgImage={whatIsBackground}
                bgImageAlt="Man hiking with camera"
                strength={100}
                className={classes.parallaxImage}
                >
        {/* <div className={classes.imageWrapper}> */}
            <Section>
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <SectionHeading text="What Is Video Modify?" alignOverride="left" />
                        <SectionContent text={listHeading} />
                        <SectionList list={listItems} />
                        {/*paragraphs.map((paragraph, index) => (
                            <SectionContent key={index} text={paragraph} />
                        ))*/}
                    </Grid>
                    <Grid item md={6} xs={12}>
                    </Grid>
                </Grid>
            </Section>
            </Parallax>
        {/* </div> */}
        <SectionDivider color={'transparent'}/>
        </React.Fragment>
      </div>
    )
}

WhatIs.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(WhatIs)

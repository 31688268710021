import React from 'react'
import {withStyles} from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'


const styles = (theme) => ({
    root: {
       justifyContent: 'center', 
    },
    video: {
        borderRadius: '0.25rem',
        width: '100%',
        minHeight: '13.75rem',
        [theme.breakpoints.up('md')]: {
            minHeight: '30rem',
        }
    },
    title: {
        marginBottom: '1rem',
    }
})

const PatientExampleVideo = (props) => (
    <React.Fragment>
        <Typography className={props.classes.title} variant={'title'} >Patient Example Video</Typography>
        <iframe title="Patient Example Video" className={props.classes.video} src="https://player.vimeo.com/video/198894789" frameBorder="0"  allowFullScreen></iframe>
    </React.Fragment>    
)
export const PatientExample = withStyles(styles)(PatientExampleVideo)

const SharedPatientVideo = (props) => (
    <React.Fragment>
        <Typography className={props.classes.title} variant={'title'} >Example Shared Video</Typography>
        <iframe title="Shared Patient Video" className={props.classes.video} src="https://player.vimeo.com/video/177596071" frameBorder="0" allowFullScreen></iframe>
    </React.Fragment>
)
export const SharedPatient = withStyles(styles)(SharedPatientVideo)

const UnhealthyPatientVideo = (props) => (
    <React.Fragment>
        Unhealthy Patient Video
        <iframe title="Unhealthy Patient Video" className={props.classes.video} src="https://player.vimeo.com/video/188768538" frameBorder="0"  allowFullScreen></iframe>
    </React.Fragment>
)
export const UnhealthyPatient = withStyles(styles)(UnhealthyPatientVideo)

const BestOneLowestPriceVideos = (props) => (
    <React.Fragment>
        <Typography variant={'title'} className={props.classes.title}>
        Best One Lowest Price Videos
        </Typography>
        <Typography variant={'title'} className={props.classes.title}>
        Best 4K Monitor
        </Typography>
        <iframe title="Best 4K Monitor" className={props.classes.video} src="https://player.vimeo.com/video/266937592" frameBorder="0"  allowFullScreen></iframe>
        <Typography variant={'title'} className={props.classes.title}>
        Best Motorcycle Gloves
        </Typography>
        <iframe title="Best Motorycle Gloves" className={props.classes.video} src="https://player.vimeo.com/video/266937750" frameBorder="0" allowFullScreen></iframe>
        <Typography variant={'title'} className={props.classes.title}>
        Best Wifi Lightbulbs
        </Typography>
        <iframe title="Best Wifi Lightbulbs" className={props.classes.video} src="https://player.vimeo.com/video/266937693" frameBorder="0"  allowFullScreen></iframe>
        <Typography variant={'title'} className={props.classes.title}>
        Best Police Scanner
        </Typography>
        <iframe title="Best Police Scanner" className={props.classes.video} src="https://player.vimeo.com/video/266937633" frameBorder="0"  allowFullScreen></iframe>
    </React.Fragment>
)

export const BestOneLowestPrice = withStyles(styles)(BestOneLowestPriceVideos)

const BankingCustomerVideo = (props) => (
    <React.Fragment>
        <Typography variant={'title'} className={props.classes.title}>
        Banking Customer Example Video
        </Typography>
        <iframe title="Banking Customer Video" className={props.classes.video} src="https://player.vimeo.com/video/266937815" frameBorder="0" allowFullScreen></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </React.Fragment>
)

export const BankingCustomer = withStyles(styles)(BankingCustomerVideo)
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const textColor = 'rgba(0, 0, 0, 0.66)'
const textWeight = 300

const styles = (theme) => ({
    contentSection: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        letterSpacing:'1px',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '3rem',
            paddingRight: '3rem',
        },
    },
    content: {
        fontWeight: textWeight,
        fontSize: '0.9rem',
        lineHeight: '2.0rem',
        color: textColor,
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
            lineHeight: '2.25rem',
        },
    },
    centered: {
      textAlign: 'center'
    }
})

const SectionContent = (props) => {
    const { classes, text } = props
    if(props.center){
      return (
          <div className={classes.contentSection}>
            <p className={classes.content + ' ' + classes.centered} dangerouslySetInnerHTML={{__html: text}}></p>
              {/*<Typography paragraph={true} className={classes.content + ' ' + classes.centered}>
                  {text}
              </Typography>*/}
              {props.children}
          </div>
      )
    }else{
      return (
          <div className={classes.contentSection}>
            <p className={classes.content} dangerouslySetInnerHTML={{__html: text}}></p>
              {/*<Typography paragraph={true} className={classes.content}>
                  {text}
              </Typography>*/}
              {props.children}
          </div>
      )
    }
}

SectionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string,
    center: PropTypes.bool,
}

export default withStyles(styles)(SectionContent)

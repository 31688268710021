import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { Parallax } from 'react-parallax'

import eyesGirl from '../assets/eyes-girl-high-res.jpg'
import mobileEyesGirl from '../assets/eyes-girl-mobile-high-res.jpg'
import arrowDown from '../assets/arrow-solid-long.svg'


const styles = (theme) => ({
    parallaxImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 600,
        width: '100%',
        padding: 0,
        [theme.breakpoints.up('lg')]: {
            //minHeight: 500,
        },
        [theme.breakpoints.up('xl')]: {
            //minHeight: 500,
        }
    },
    mobileParallaxImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 250,
        width: '100%',
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            minHeight: 600,
        },

    },
    imageText: {
        paddingTop: '3rem',
        fontWeight: 300,
        fontSize: '1.5rem',
        letterSpacing: 3,
        color: 'white',
        [theme.breakpoints.up('md')]: {
            paddingTop: '3rem',
        }
    },
    overlayContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    arrowDown: {
        marginTop: '2rem',
        height: '3rem',
        [theme.breakpoints.up('md')]: {
            marginTop: '3rem',
            height: '5rem',
        }
    }
})

const imageText = "Video Modify can be tailored to fit any industry"

const Eyes = (props) => {
    const { classes } = props

    const ImageTextOverlay = (text) => (
        <div className={classes.overlayContainer}>
            <Typography className={classes.imageText}>
                {imageText}
            </Typography>
            <img src={arrowDown} alt="Down arrow" className={classes.arrowDown} />
        </div>
    )
    return(
      <React.Fragment>
        <Hidden mdUp>
        <Parallax
            blur={0}
            bgImage={mobileEyesGirl}
            bgImageAlt={"Stylistic, Woman looking toward the future"}
            strength={100}
            className={classes.mobileParallaxImage}
        >
        {ImageTextOverlay(imageText)}
        </Parallax>
        </Hidden>
        <br></br>
        <Hidden smDown>
        <Parallax
            blur={0}
            bgImage={eyesGirl}
            bgImageAlt={"Stylistic, Woman looking toward the future"}
            strength={100}
            className={classes.parallaxImage}
        >
        {ImageTextOverlay(imageText)}
        </Parallax>
        </Hidden>
      </React.Fragment>
    )
}

Eyes.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Eyes)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'
import arrowDown from "../assets/arrow-down-short.svg"
import SectionHeading from '../components/SectionHeading'

const styles = (theme) => ({
    sectionwrap: {
      paddingTop:'50px',
    },
    videoWrapper: {
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    video: {
        borderRadius: '0.25rem',
    },
    arrow: {
        paddingTop: '2rem',
        paddingBottom: '0rem',
        width: '2rem',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '2.5rem',
            width: '2.5rem',
        }
    }
})

const heading = "Video Modify ::: An Introduction"
const poster = "https://storage.googleapis.com/cdn.videomodify.com/videos/introduction/poster.png"
const videoURL = "https://storage.googleapis.com/cdn.videomodify.com/videos/introduction/introduction.mp4"
const hlsURL = "https://storage.googleapis.com/cdn.videomodify.com/videos/introduction/master.m3u8"

const Introduction = (props) => {
    const { classes } = props
    return (
        <React.Fragment>
          <div className={classes.sectionwrap}>
            <SectionHeading text={heading} />
            <div className={classes.videoWrapper}>
                <ReactPlayer
                    url = {hlsURL}
                    config={{
                        file: {
                            attributes: {
                                poster: poster,
                                preload: 'none',
                                controls: true,
                            },
                        }
                    }}
                    width='80%'
                    height='100%'
                />
            </div>
            <img alt="Down Arrow" src={arrowDown} className={classes.arrow} />
          </div>
        </React.Fragment>
    )
}

Introduction.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Introduction)

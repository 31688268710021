import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Parallax } from 'react-parallax'

import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionList from '../components/SectionList'
import bgimage from '../assets/benefits-back-img-only-high-res-0.jpg'
import bgimagemobile from '../assets/benefits-back-img-only-high-res.jpg'
import ContactUsButton from '../components/ContactUsButton'


const styles = (theme) => ({
    imageWrapper: {
      backgroundColor: '#eef0f0',

        [theme.breakpoints.up('md')]: {
            //background: `url(${bgimage}) no-repeat right center`,
            //backgroundSize: '50%, 400%',
            backgroundImage: `url(${bgimage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            //backgroundSize: '50%, 400%',
        }
    },
    benefitText: {
        textShadow: 'rgba(255, 255, 255, 0.5) 1px 0 2px',
    },
    mobileParallaxImage: {
        width: '100%',
        minHeight: 250,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    parallaxImage: {
        width: '100%',
        minHeight: 900,
    },
    belowcontent: {
      width: '100%',
      height: '0px',
      [theme.breakpoints.up('sm')]: {
          height: '50px',
      },
    }
})
const heading = "What are the benefits of Personalized Video?"
const bulletPoints = [
    '<strong>Video is fast, easy, and entertaining</strong>',
    'Increases brand equity and customer engagement',
    'Reduces the cost of printed materials/postage',
    'Connects with viewers personally through targeted messaging',
    'Provides trackable data analysis',
    'Reduces the cost of 1:1 professional consultations',
    'Offers a new, strategic marketing platform'
]
const Benefits = (props) => {
    const { classes } = props
    return(
      <div id='benefits'>
        <React.Fragment>
            <Hidden mdUp>
                <Parallax
                    blur={0}
                    bgImage={bgimagemobile}
                    bgImageAlt="Man using an iPad"
                    strength={100}
                    className={classes.mobileParallaxImage}
                    />
            </Hidden>
            <div className={classes.imageWrapper}>
                <Section>
                    <Grid container>

                        <Grid item xs={12} md={6}>
                            <SectionHeading text={heading} alignOverride="left" />
                            <SectionList list={bulletPoints} />
                            {/* <ul className={classes.listFormat}>
                                {bulletPoints.map((text, index) => (
                                    <li key={index}>
                                        <SectionContent text={text} />
                                    </li>
                                ))}
                            </ul> */}
                            <ContactUsButton text={"Get Started"}/>
                            <div class={classes.belowcontent}></div>
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>
                    </Grid>
                </Section>
            </div>
        <SectionDivider color={'transparent'}/>
        </React.Fragment>
      </div>
    )
}

Benefits.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Benefits)

import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import ReactGA from 'react-ga4'
import 'scroll-behaviour/polyfill'

import Home from './modules/Home'
import Header from './components/Header'
import Footer from './components/Footer'
import ToTopButton from './components/ToTopButton'

import {
    PatientExample,
    SharedPatient,
    BestOneLowestPrice,
    UnhealthyPatient,
    BankingCustomer,
} from './modules/Legacy'

const styles = (theme) =>({
    root: {
        backgroundColor: '#FDFBFC',
        textAlign: 'center',
        paddingBottom: 50,
    },
    toTop: {
        position: 'fixed',
        bottom: 0,
        left: 20,
    },
})

class App extends Component {

    render() {
        ReactGA.initialize('G-KV65RVLRRF');
        ReactGA.send("pageview");

        const menu = [
            //'Introduction',
            'Why Video',
            'What Is Video Modify?',
            'Benefits',
            'How Does It Work?',
            //'Industries',
            //'News',
            'Contact',
        ]

        const { classes } = this.props

        return (
            <Router>
                <div className={classes.root}>
                    <Header menuItems={menu}/>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/videos/39j0skd-3d/Patient_Example_Vid.html" component={PatientExample}/>
                    <Route exact path="/videos/39j0skd-3d/videoID.html"  component={SharedPatient}/>
                    <Route exact path="/videos/BOLP_9neks0/BestOneLowestPrice.html" component={BestOneLowestPrice}/>
                    <Route exact path="/videos/PFV-98dd/Banking_Customer_Example.html" component={BankingCustomer}/>
                    <Route exact path="/videos/PFV-98dd/VideoID.html" component={SharedPatient} />
                    <Route exact path="/videos/cmkdjs998473jhw/00984unhealthy.html" component={UnhealthyPatient}/>
                    <Route exact path="/videos/cmkdjs998473jhw/videoID.html" component={SharedPatient}/>
                    <Footer menu={menu}/>
                    <div className={classes.toTop}>
                        <ToTopButton/>
                    </div>
                </div>
            </Router>
        )
    }
}

export default withStyles(styles)(App)

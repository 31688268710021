import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Banner from './Banner'
import ResponsiveMenuBar from './Menu'

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    header: {
        width: '98%'
    },
})

const Header = (props) => {
    const { menuItems, classes } = props
    return (
        <div className={classes.root}>
            <div className={classes.header}>
            <ResponsiveMenuBar menuItems={menuItems} />
            <Banner
                headline='Videomodify'
                subheading='Personalized Video Intelligence'
            />
            </div>
        </div>
    )
}

Header.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    banner: PropTypes.bool,
}

export default withStyles(styles)(Header)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const textColor = 'rgba(0, 0, 0, 0.66)'
const textWeight = 300

const styles = (theme) => ({
    root: {
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '1.5rem',
        }
    },
    listItem: {
        fontWeight: textWeight,
        fontSize: '1rem',
        lineHeight: '2rem',
        color: textColor,
    },
})

const SectionList = (props) => {
    const { classes, list } = props

    return (
        <div className={classes.root}>
            <ul>
            {list.map((item, index) => (
                <li key={index}>
                  <p className={classes.listItem} dangerouslySetInnerHTML={{__html: item}}></p>
                    {/*<Typography className={classes.listItem}>
                        {item}
                    </Typography>*/}
                </li>
            ))}
            </ul>
        </div>
    )
}

SectionList.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default withStyles(styles)(SectionList)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { HashLink as Link } from 'react-router-hash-link'

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        //width: '100%',
        marginTop: '30px',
        paddingLeft: '1rem',
        paddingBottom: '2rem',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '3rem' //3rem
        }
    },
    button: {
        backgroundColor: '#EF4136',
        boxShadow:'none',
        borderRadius:'0px',
        padding:'16px 20px',
    },
    buttonLink: {
        textDecoration: 'none',
        color: 'white',
        letterSpacing: '1px',
    }
})

const ContactUsButton = (props) => {
    const { classes, text } = props
    return (
        <div className={classes.root}>
          <Button variant={"raised"} className={classes.button}>
              <Link to={'#contact'} smooth className={classes.buttonLink}>
                  {text}
              </Link>
          </Button>
        </div>
    )
}

ContactUsButton.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
}

export default withStyles(styles)(ContactUsButton)

import React from 'react'

import Introduction from './Introduction'
import GetPersonal from './GetPersonal'
import WhatIs from './WhatIs'
import Finance from './Finance'
//import Industry from './Industry'
import Healthcare from './Healthcare'
import HowDoes from './HowDoes'
import Easy from './Easy'
// import News from './News'
import ContactUs from './ContactUs'
import Benefits from './Benefits'
import VideoVSText from './VideoVSText'
import Eyes from './Eyes'


export default (props) => {
    return (
        <React.Fragment>
            <Introduction/>
            <GetPersonal/>
            <WhatIs/>
            <VideoVSText/>
            <Benefits />
            <HowDoes />
            <Easy />
            <Eyes />
            {/*<Industry />*/}
            <Healthcare />
            <Finance />
            <ContactUs />
        </React.Fragment>
    )
}

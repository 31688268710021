import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { HashLink as Link } from 'react-router-hash-link'

import {slugify} from '../utils'
import logo from '../assets/logo-horz.svg'
import verticalDivider from '../assets/vertical-divider.svg'


const textColor = 'rgba(0, 0, 0, 0.5)'
const styles = (theme) => ({
    root: {
        //margin: 10,
        padding: 20,
        backgroundColor: '#f3f3f3',
        textAlign:'left',
        [theme.breakpoints.up('md')]:{
            textAlign:'left'
        },
    },
    firstGrid: {
      borderRight: 'none',
      [theme.breakpoints.up('md')]:{
          borderRight: '1px solid #EF4136',
      },
    },
    logo: {
        width: '7rem',
        height: '8rem',
        paddingTop: 20,
        paddingBottom: 20,
    },
    logonew: {
      width:'300px',
      marginBottom:'30px'
    },
    contentnew: {
      color: '#7e7e7d',
      fontSize: '10px',
      fontWeight: '100',
      lineHeight: '22px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    copyrightline: {
      color: '#EF4136',
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    logoCaption: {
        color: textColor,
        letterSpacing: 5
    },
    logoCopy: {
        color: textColor,
        fontWeight: 100
    },
    footerLink: {
        textDecoration: 'none',
        lineHeight: 3,
        color: textColor,
    },
    menuWrapper: {
        display: 'flex',
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    newletterWrapper: {
        display: 'flex',
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center'
    },
    newsletterSignup: {
        paddingTop: 10,
        paddingBottom: 20,
    },
    emailIcon: {
        height: 40,
        width: '100%',
        paddingBottom: 20,
    },
    thankYou: {
        textAlign: 'left',
        color: textColor
    },
    verticalDivider: {
        height: 200,
        paddingLeft: 25,
        borderRight: '1px solid #EF4136',
        width:'1px',
        position:'absolute'
    },
    attributeText: {
        paddingTop: '1rem',
        color: textColor,
        fontSize: '0.75rem',
    },
    attributeSection: {
        display: 'flex',
        flex: '1',
        minHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }
})

const Footer = (props) => {
    const { classes, menu } = props
    const attributeText = "Special Thanks: Colby Moore - Filmographer."
    const sectionMenu = (menu, columns) => {
        /*
        Assuming the order provided is intentional, let's reverse it so pop()
        doesn't cause us to reverse the order on the output when iterating through
        */
        const deepMenuCopy = Object.assign([], menu)
        const reversedMenu = deepMenuCopy.reverse()
        const menuSections = Array.from(Array(columns), () => [])
        while(reversedMenu.length !== 0){
            for(const column of menuSections) {
                const currentMenuItem = reversedMenu.pop()
                // Check to make sure we didn't run out of entries
                if(currentMenuItem === undefined){
                    break
                } else {
                    column.push(currentMenuItem)
                }
            }

        }
        return menuSections
    }
    const menuSections = sectionMenu(menu, 2)
    return (
      <div className={classes.root}>
        <img src={logo} className={classes.logonew}/>
        <div className={classes.contentnew}>
          Special Thanks To<br />
          Colby Moore - Filmographer<br />
          Demitri Collins - Videographer<br />
          <div className={classes.copyrightline}>&copy; Video Modify &nbsp;&nbsp;|&nbsp;&nbsp;<a className={classes.copyrightline} href="mailto:info@videomodify.com">info@videomodify.com</a></div>
        </div>

            {/*<Grid container>*/}
                {/*<Grid item xs={12} md={3} className={classes.firstGrid}>
                    <div className={classes.logoWrapper}>
                        <img alt="VideoModify Logo" src={logo} className={classes.logo}/>
                    </div>
                    <Typography className={classes.logoCaption} variant='title'>VIDEOMODIFY</Typography>
                    <Typography className={classes.logoCopy} variant='subheading'>&copy; VIDEOMODIFY 2018</Typography>
                </Grid>*/}
                {/*<Hidden smDown>
                <Grid item md={1} className={classes.verticalDivider}>
                    <img alt="Vertical Divider" className={classes.verticalDivider} src={verticalDivider} />
                </Grid>
                </Hidden>*/}
                {/*{menuSections.map((section, index) => (
                    <Grid item key={index} xs={12} md={3}>
                        <div className={classes.menuWrapper}>
                        {section.map((item, index) => (
                            <Typography key={index}>
                            <Link key={index} smooth className={classes.footerLink} to={`/#${slugify(item)}`}>{item} </Link>
                            </Typography>
                        ))}
                        </div>
                    </Grid>
                ))}
                <Grid item xs={12} md={3}>
                <div className={classes.attributeSection}>
                <Typography className={classes.attributeText}>
                    {attributeText}
                </Typography>
                </div>
                </Grid>*/}
                {/* <Grid item xs={12} md={3}>
                    <div className={classes.newletterWrapper}>
                        <img src={email} className={classes.emailIcon}/>
                        <TextField
                            id='email-signup'
                            label='Email Newsletter Signup'
                            className={classes.newsletterSignup}
                        />
                        <Typography className={classes.thankYou}>
                            Special Thanks to::: Leon Russell, Beth Ringle, Brad Pitt, and Wayman Tisdale.
                        </Typography>
                    </div>
                </Grid> */}
            {/*</Grid>*/}
      </div>
    )
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Footer)

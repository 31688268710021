import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { Parallax } from 'react-parallax'

import { slugify } from '../utils'
import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionContent from '../components/SectionContent'
import ContactUsButton from '../components/ContactUsButton'
import easyBackground from '../assets/finance1-high-res.jpg'
import mobileEasyBackground from '../assets/finance-back-img-only-low-res.jpg'

const styles = (theme) => ({
    imageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: `url(${easyBackground}) center center no-repeat`,
            backgroundSize: '150%, 100%',
            paddingTop: 20,
            paddingBottom: 50,
        }
    },
    parallaxImage: {
        '& > img': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
            }
        }
    },
    mobileParallaxImage: {
        width: '100%',
        minHeight: 250,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    imageText: {
        textShadow: 'rgba(255, 255, 255, 0.75) 1px 0 3px'
    },
})

const heading = "Finance"

const paragraphs = [
    '<strong>Video Modify transforms the way financial companies deliver advice.</strong> Our personalized finance videos give consumers clear, step-by-step advice to manage their money and achieve their personal finance goals. This video solution creates video action plans for companies to guide viewers through their specific debt repayment options, savings plans, and budget strategies.',
    'Video Modify’s automated technology <strong>makes it easy for companies to deliver personalized financial advice in real time, without surrendering the familiarity of an in-person consultation</strong>. Whether they’re saving for a home or paying off a loan, Video Modify engages consumers across all channels and touch points, with video advice that’s right on the money.',
]

const Easy = (props) => {
    const { classes } = props
    return(
        <React.Fragment>
            <Hidden mdUp>
                <Parallax
                    blur={0}
                    bgImage={mobileEasyBackground}
                    bgImageAlt="Macbook and Coffee on a Table"
                    strength={100}
                    className={classes.mobileParallaxImage}
                    />
            </Hidden>
            <Parallax
                blur={0}
                bgImage={easyBackground}
                bgImageAlt="Macbook and Coffee on a Table"
                strength={100}
                className={classes.parallaxImage}
                >
        {/* <div className={classes.imageWrapper}> */}
            <Section>
                <div id={slugify(heading)} />
                <Grid container>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                        <SectionHeading text={heading} className={classes.heading} alignOverride="left"/>
                        {paragraphs.map((paragraph, index) => (
                            <SectionContent key={index} text={paragraph} />
                        ))}
                        <ContactUsButton text={'Get Started'} />
                    </Grid>

                </Grid>
            </Section>
            </Parallax>
        {/* </div> */}
        <SectionDivider color={'transparent'}/>
        </React.Fragment>
    )
}

Easy.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Easy)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'react-router-dom'

import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionContent from '../components/SectionContent'
import lineShape from '../assets/line-shape-adj.svg'


const styles = (theme) => ({
    root: {
        background: 'linear-gradient(115deg, #dfdf73 35%, #dbdbd9 30%)',
    },
    buttonLink: {
        textDecoration: 'none',
    },
    dividerImage: {
        width: '100%',
        margin: 0,
        padding: 0,
    },
    parallaxDividerImage: {
        minHeight: 400,
        width: '100%',
        padding: 0,
        [theme.breakpoints.up('lg')]: {
                minHeight: 500,
        },
        [theme.breakpoints.up('xl')]: {
            minHeight: 500,
        }
    },
    mobileParallaxImage: {
        minHeight: 250,
        width: '100%',
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    contactUsLink: {
        textAlign: 'left',
        color: 'black',
        width: 'auto',
        fontWeight: 300,
        paddingBottom: '1rem',
        /*[theme.breakpoints.up('md')]:{
            textAlign: 'center',
            marginRight: '0rem',
            paddingBottom: 0,
        }*/
    },
    contentwrap: {
      [theme.breakpoints.up('lg')]:{
          paddingRight: '5rem'
      }
    }
})

const content = [
    {
        heading: 'How does Video Modify work?',
        text0: `Video Modify’s technology simulates the millions of human decisions that influence the creation of a video. From macro-decisions, like selecting the right scenes for the right viewer at the right time, to micro-decisions, like pixel-perfect motion graphic placement.`,
        text1: `Each decision is represented by algorithms that are converted into calculations and processed at high speed to <strong>create seamless, one-to-one video experiences</strong>.`,
        cta: `Fill out the contact form to get started today! >`
    },
]

const HowDoes = (props) => {
    const { classes } = props
    return(
      <div id="how-does-it-work" className={classes.root}>
        <Section>

        <Grid container>
          <Hidden smDown>
              <Grid item md={6}>
                  <img src={lineShape} alt="Stylistic shaped lines" />
              </Grid>
          </Hidden>
            <Grid item xs={12} md={6}>
              <div className={classes.contentwrap}>
            {content.map((content, index) => (
                <React.Fragment key={index}>
                    <SectionHeading text={content.heading} alignOverride="left" />
                    <SectionContent text={content.text0} />
                    <SectionContent text={content.text1} />
                    <SectionContent><Link to='/#contact' className={classes.contactUsLink}>{content.cta}</Link></SectionContent>
                </React.Fragment>
            ))}
              </div>
            </Grid>

        </Grid>
        </Section>
      </div>
    )
}

HowDoes.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(HowDoes)

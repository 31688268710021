import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
    root: {
        borderColor: '#ED4037',
        width: 'auto',
        margin: '0',
    },
})

const SectionDivider = (props) => {
    const { classes, color } = props
    return (
        <hr className={classes.root} style={{borderColor: color || 'red'}} />
    )
}

SectionDivider.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SectionDivider)
import PropType from 'prop-types'


export const slugify = (string) => {
    string = string.replace(/[^\w\s]/gi, '') // Remove special characters
    string = string.replace(/ /g, '-') // Remove spaces, substitute hyphens
    return string.toLowerCase()
}
slugify.propTypes = {
    string: PropType.string.isRequired
}
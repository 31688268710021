import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import oceanGraphic from '../assets/form-back-high-res.jpg'
import rectangle from '../assets/rectangle.svg'
import silo from  '../assets/silo.svg'


const styles = (theme) => ({
    letsgetstarted: {
      padding: '50px 0px',
      [theme.breakpoints.up('lg')]: {
          padding: '75px 0px',
      }
    },
    imageWrapper: {
        background: `url(${oceanGraphic}) bottom center no-repeat`,
        backgroundSize: '130%, 100%',
        backgroundColor: '#E0E2DF',
        paddingBottom: '10rem',
        [theme.breakpoints.up('md')]: {
            paddingTop: '4rem',
            paddingBottom: '5rem',
        }
    },
    form: {
        width: '95%',
        padding: '2rem',
        [theme.breakpoints.up('md')]: {
            width: '65%',
        }
    },
    formWrapper: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'left',
            marginTop: '1rem',
            paddingLeft: '3rem',
        }
    },
    formEntry: {
        paddingBottom: '2rem',
        textAlign: 'left',
        fontWeight:'100'
    },
    floatingLabelStyle: {
      color: '#000 !important',
    },
    submitButton: {
        marginTop: '2rem',
        width: '5rem',
        color: 'white',
        backgroundColor: '#EF4136',
        boxShadow:'none',
        borderRadius:'0px',
    },
    contactHeading: {
        color: 'rgba(0, 0, 0, 0.5)',
        textAlign: 'left',
        paddingBottom: '2rem',
        fontSize: '14px'
    },
    formGraphics: {
        position: 'relative',
        height: '100%',
    },
    formGraphic: {
        position: 'absolute',
        width: '100%',
        bottom: '2rem',
        left: '2rem',
        [theme.breakpoints.up('lg')]: {
            bottom: '4rem',
            left: '4rem',
        },
        [theme.breakpoints.up('xl')]: {
            bottom: '2rem',
        }

    },
    formGraphicOverlay: {
        minHeight: 200,
        position: 'absolute',
        width: '130%',
        top: '-1rem',
        right: '-10rem',
        [theme.breakpoints.up('xl')]: {
            right: '-12rem',
        }
    },
})

class ContactUs extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        comment: '',
        formDisabled: false,
        formSuccess: false,
    }
    sendMail = async () => {
        const {name, email, phone, comment} = this.state
        if(name && email && phone && comment) {
        const base_uri = 'https://us-central1-videomodify-184721.cloudfunctions.net/contactUs'
        const params = `?name=${name}&email=${email}&phone=${phone}&comment=${comment}`
        const url = base_uri + params
            try {
                this.setState({'formDisabled': true})
                const response = await fetch(url)
                if(response.status >= 200 && response.status < 300) {
                    alert("Thank you, we will be in touch with you soon.")
                    this.setState({'formSucces': true})
                } else {
                    this.setState({'formDisabled': false})
                    throw new Error({status: response.status, message: response.statusText})
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
    handleNameChange = (event) => {
        this.setState({'name': event.target.value})
    }
    handleEmailChange = (event) => {
        this.setState({'email': event.target.value})
    }
    handlePhoneChange = (event) => {
        this.setState({'phone': event.target.value})
    }
    handleCommentChange = (event) => {
        this.setState({'comment': event.target.value})
    }
    render() {
        const { classes } = this.props
        return (
          <div id="contact">
            <div className={classes.letsgetstarted}>
              <SectionHeading text="Let's get started" />
            </div>
            <React.Fragment>
                <div className={classes.imageWrapper}>
                    <div className={classes.formWrapper}>
                        <Paper className={classes.form}>
                        <Grid container>
                            <Grid item xs={12} md={8} lg={9}>
                                <Typography className={classes.contactHeading}>Take a minute to fill out the form below</Typography>
                                <FormGroup className={classes.formInputWrapper}>
                                    <TextField
                                        className={classes.formEntry}
                                        disabled={this.state.formDisabled}
                                        onChange={this.handleNameChange}
                                        floatingLabelStyle={styles.floatingLabelStyle}
                                        label='Name'
                                    />
                                    <TextField
                                        className={classes.formEntry}
                                        disabled={this.state.formDisabled}
                                        onChange={this.handleEmailChange}
                                        floatingLabelStyle={styles.floatingLabelStyle}
                                        label='Email'
                                    />
                                    <TextField
                                        className={classes.formEntry}
                                        disabled={this.state.formDisabled}
                                        onChange={this.handlePhoneChange}
                                        floatingLabelStyle={styles.floatingLabelStyle}
                                        label='Phone'
                                    />
                                    <TextField
                                        multiline
                                        className={classes.formEntry}
                                        disabled={this.state.formDisabled}
                                        onChange={this.handleCommentChange}
                                        floatingLabelStyle={styles.floatingLabelStyle}
                                        label='Tell Us About Your Company'
                                    />
                                    <Button
                                        className={classes.submitButton}
                                        disabled={this.state.formDisabled}
                                        onClick={this.sendMail}
                                        variant='raised'>
                                        Submit
                                    </Button>
                                </FormGroup>
                            </Grid>
                            <Hidden smDown><Grid item md={3} lg={2}>
                                <div className={classes.formGraphics}>
                                    <img alt="Graphical styling, gray polygon" src={silo} className={classes.formGraphic} />
                                    <img alt="Graphical styling, red rectangle" src={rectangle} className={classes.formGraphicOverlay} />
                                </div>
                            </Grid></Hidden>
                        </Grid>
                        </Paper>
                    </div>
                </div>
                <SectionDivider color={'transparent'} />
            </React.Fragment>
          </div>
        )
    }
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ContactUs)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { Parallax } from 'react-parallax'

import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionContent from '../components/SectionContent'
import ContactUsButton from '../components/ContactUsButton'
import ipadMan from '../assets/guy-with-ipad-high-res.jpg'
import mobileiPadMan from '../assets/guy-with-ipad-mobile-high-res.jpg'

const styles = (theme) => ({
    imageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: `url(${ipadMan}) top center no-repeat`,
            backgroundSize: '150%, 100%',
            paddingTop: 20,
            paddingBottom: 50,
        }
    },
    parallaxImage: {
        '& > img': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
            }
        }
    },
    mobileParallaxImage: {
        width: '100%',
        minHeight: 250,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    imageText: {
        textShadow: 'rgba(255, 255, 255, 0.75) 1px 0 3px'
    },
    belowcontent: {
      width: '100%',
      height: '0px',
      [theme.breakpoints.up('sm')]: {
          height: '50px',
      },
    }
})

const heading = "Video vs. Text"

const paragraphs = [
  'Currently, important personal information is frequently delivered via text. However, text has distinctive drawbacks compared to the power of video. Storytelling and visual explanation is human nature. But video is more than an enjoyable leisure activity, retention rates are higher too.  <strong>Studies show that people will retain 95% of a message in a well-produced video</strong>, while retaining only 10% of that same message when presented in text.'
]

const VideoVSText = (props) => {
    const { classes } = props
    return(
      <div id="why-video">
        <React.Fragment>
            <Hidden mdUp>
                <Parallax
                    blur={0}
                    bgImage={mobileiPadMan}
                    bgImageAlt="Man using an iPad"
                    strength={100}
                    className={classes.mobileParallaxImage}
                    />
            </Hidden>
            <Parallax
                blur={0}
                bgImage={ipadMan}
                bgImageAlt="Man using an iPad"
                strength={100}
                className={classes.parallaxImage}
                >
        {/* <div className={classes.imageWrapper}> */}
            <Section>

                <Grid container>
                    <Grid item md={6} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <SectionHeading text={heading} className={classes.heading} alignOverride="left" />
                        {paragraphs.map((paragraph, index) => (
                            <SectionContent key={index} text={paragraph} />
                        ))}
                        <ContactUsButton text={"Get Started"}/>
                        <div className={classes.belowcontent}></div>
                    </Grid>
                </Grid>
            </Section>
            </Parallax>
        {/* </div> */}
        <SectionDivider color={'transparent'}/>
        </React.Fragment>
      </div>
    )
}

VideoVSText.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(VideoVSText)

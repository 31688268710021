import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import logo from '../assets/logo-symbol-only.svg' //logo-only-hero.svg Logo-revised-white.svg
import logotext from '../assets/logo-type-only.svg' //logo-only-hero.svg Logo-revised-white.svg
import envelopeCropped from "../assets/envelope-cropped.svg"
import arrowDown from "../assets/arrow-down-short.svg"

const styles = (theme) => ({
    root: {
        justifyContent: 'center',
    },
    logoWrapper: {
        backgroundColor: '#ED4037',
        paddingTop:'20px',
        [theme.breakpoints.up('sm')]: {
            maxHeight: '6rem'
        },
        [theme.breakpoints.up('md')]:{
            maxHeight: '8rem',
            paddingTop:'0px',
        },
    },
    header: {
        transition: theme.transitions.create(['top'], {
            easing: theme.transitions.easing.sharp,
            duration: 50
        }),
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            top: '0rem' //0
        },
        [theme.breakpoints.up('md')]: {
            top: '3rem', //3, 0
        },
        [theme.breakpoints.up('lg')]: {
            top: '4rem', //5, 0
        }
    },
    heading: {
        letterSpacing: 10, //10
        fontWeight: 500,
        color: 'white',
        [theme.breakpoints.up("md")]: {
            fontSize: '2rem',
        }
    },
    subHeading: {
        fontWeight: 300,
        letterSpacing: 6,
        color: 'white',
        fontSize: '0.6rem',
        width: '255px',
        margin: '0px auto',
        [theme.breakpoints.up("md")]: {
            fontSize: '1rem',
            width: '100%',
        }
    },
    logo: {
        height: '6rem', //6, 10
        width: '6rem', //6, 12
        paddingBottom: '1rem',
        paddingTop: '0rem',
        transition: theme.transitions.create(['padding'], {
            easing: theme.transitions.easing.sharp,
            duration: 100
        }),
        [theme.breakpoints.up("sm")]: {
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: '0rem',
            paddingBottom: '2rem',
            width: '8rem', //15, 30
            height: '8rem', //15, 18
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: '2rem',
            paddingBottom: '2rem',
            width: '12rem', //15, 30
            height: '12rem', //15, 18
        },
    },
    logotext: {
      height: '2rem',
      width: '14rem',
      [theme.breakpoints.up("md")]: {
          height: '2rem',
          width: '24rem',
          paddingBottom:'1rem',
      },
      [theme.breakpoints.up("lg")]: {
          height: '4rem',
          width: '28rem',
          paddingBottom:'1rem',
      },
    },
    envelope: {
        width: '100%'
    },
    arrow: {
        paddingTop: '2rem',
        paddingBottom: '1rem',
        width: '2rem',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '2.5rem',
            width: '2.5rem',
        }
    }
})

const Banner = (props) => {
    const {headline, subheading, classes} = props
    return (
        <div className={classes.root}>
            <div className={classes.logoWrapper}>
            <section className={classes.header}>
            <img alt="Video Modify Logo" src={logo} className={classes.logo} /><br/>
            <img alt="Video Modify Logo" src={logotext} className={classes.logotext} />
            {/*<Typography className={classes.heading} variant='headline'>{headline.toUpperCase()}</Typography>*/}
            <Typography className={classes.subHeading} variant='headline'>{subheading.toUpperCase()}</Typography>
            </section>
            </div>
            <img alt="Cropped Envelope Tip" src={envelopeCropped} className={classes.envelope} />
            {/*<img alt="Down Arrow" src={arrowDown} className={classes.arrow} />*/}
        </div>

    )
}

Banner.propTypes = {
    headline: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Banner)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { Parallax } from 'react-parallax'

import { slugify } from '../utils'
import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionContent from '../components/SectionContent'
import ContactUsButton from '../components/ContactUsButton'
import easyBackground from '../assets/easy-back-high-res.jpg'
import mobileEasyBackground from '../assets/easy-back-mobile-high-res.jpg'

const styles = (theme) => ({
    imageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: `url(${easyBackground}) top center no-repeat`,
            backgroundSize: '150%, 100%',
            paddingTop: 20,
            paddingBottom: 50,
        }
    },
    parallaxImage: {
        '& > img': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
            }
        }
    },
    mobileParallaxImage: {
        width: '100%',
        minHeight: 250,
        [theme.breakpoints.up('sm')]: {
            minHeight: 500,
        },
    },
    imageText: {
        textShadow: 'rgba(255, 255, 255, 0.75) 1px 0 3px'
    },
    belowcontent: {
      width: '100%',
      height: '0px',
      [theme.breakpoints.up('sm')]: {
          height: '50px',
      },
    }
})

const heading = "Easy Request, Easy Delivery"

const paragraphs = [
    `Clients connect to the system using a secure API, and Video Modify takes care of the rest. Our automated process will scale to accommodate the size of any business, whether its customer base is in the hundreds or in the millions. <strong>Videos are delivered through multiple channels for viewing on tablets, smartphones, and desktops</strong>.`,
]

const Easy = (props) => {
    const { classes } = props
    return(
        <React.Fragment>
            <Hidden mdUp>
                <Parallax
                    blur={0}
                    bgImage={mobileEasyBackground}
                    bgImageAlt="Macbook and Coffee on a Table"
                    strength={100}
                    className={classes.mobileParallaxImage}
                    />
            </Hidden>
            <Parallax
                blur={0}
                bgImage={easyBackground}
                bgImageAlt="Macbook and Coffee on a Table"
                strength={100}
                className={classes.parallaxImage}
                >
        {/* <div className={classes.imageWrapper}> */}
            <Section>
                <div id={slugify(heading)} />
                <Grid container>

                    <Grid item md={6} xs={12}>
                        <SectionHeading text={heading} className={classes.heading} alignOverride="left"/>
                        {paragraphs.map((paragraph, index) => (
                            <SectionContent key={index} text={paragraph} />
                        ))}
                        <ContactUsButton text={'Get Started'} />
                        <div className={classes.belowcontent}></div>
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                </Grid>
            </Section>
            </Parallax>
        {/* </div> */}
        <SectionDivider color={'transparent'}/>
        </React.Fragment>
    )
}

Easy.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Easy)

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root')
if(rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<App />, rootElement)
} else {
    ReactDOM.render(<App />, rootElement)
    registerServiceWorker()

}
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import SectionDivider from '../components/SectionDivider'
import SectionContent from '../components/SectionContent'
import walkingMan from '../assets/guy-walking-transparent-hi-res.png'

const styles = (theme) => ({
    contentWrap: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '800px',
        margin: '0px auto'
      }
    },
    imageWrapper: {
        [theme.breakpoints.up('md')]: {
            background: `url(${walkingMan}) bottom center no-repeat`,
            backgroundSize: '100%',
            paddingBottom: '13rem',
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '16rem',
        }
    },
    contactUsLink: {
        textAlign: 'center',
        color: 'red',
        width: 'auto',
        fontWeight: 300,
        paddingBottom: '1rem',
        [theme.breakpoints.up('md')]:{
            textAlign: 'center',
            marginRight: '0rem',
            paddingBottom: 0,
        }
    }
})


const heading = "Personalize each viewer’s experience";
const paragraphs = [
    "We are all on different paths. Instead of one video for an audience of millions, <strong>make millions</strong> of videos each for an audience of <strong>one</strong>.",
];
const contactUsLink = 'Fill out the contact form to get started today! >';


const GetPersonal = (props) => {
    const { classes } = props
    return(
        <React.Fragment>
            <div className={classes.imageWrapper}>
                {/*<Section>*/}
                    <Grid container justify='center' className={classes.contentWrap}>
                        <Grid item xs={12} className={classes.heading}>
                            <SectionHeading text={heading} />
                        </Grid>
                        {paragraphs.map((paragraph, index) => (
                            <Grid item xs={12} md={12} key={index}>
                            <Grid item xs={12}>
                                <SectionContent text={paragraph} center={true} />
                            </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <SectionContent><Link to='/#contact' className={classes.contactUsLink}>{contactUsLink}</Link></SectionContent>
                        </Grid>
                    </Grid>
                {/*</Section>*/}
            </div>
            <SectionDivider color={'transparent'}/>
        </React.Fragment>
    )
}

GetPersonal.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GetPersonal)
